// Generated by Framer (d9af232)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Pv2Vu2yj4", "K0ppaNhY1", "adCH5Tcs0", "WNGq34VDP", "h_YgL_jWN"];

const serializationHash = "framer-hbW4e"

const variantClassNames = {adCH5Tcs0: "framer-v-1n84n7b", h_YgL_jWN: "framer-v-1twjamt", K0ppaNhY1: "framer-v-1ls7464", Pv2Vu2yj4: "framer-v-xg9s22", WNGq34VDP: "framer-v-5iygy8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const radiusForCorner = (value, cornerIndex) => {
    if (typeof value === "number" && Number.isFinite(value)) return Math.max(0, value) + "px";
    if (typeof value !== "string" || typeof cornerIndex !== "number") return undefined;
    const segments = value.split(" ");
    return segments[cornerIndex] || segments[cornerIndex - 2] || segments[0];
};


const transition1 = {delay: 0, duration: 0.35, ease: [0, 0, 1, 1], type: "tween"}

const transition2 = {delay: 0, duration: 1, ease: [0, 0, 1, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "Pv2Vu2yj4", "Variant 2": "K0ppaNhY1", "Variant 3": "adCH5Tcs0", "Variant 4": "WNGq34VDP", "Variant 5": "h_YgL_jWN"}

const getProps = ({background, height, id, radius, width, ...props}) => { return {...props, COj8n3hCj: background ?? props.COj8n3hCj ?? "rgb(25, 27, 39)", tGcqj6wny: radius ?? props.tGcqj6wny ?? 16, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Pv2Vu2yj4"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;radius?: number;background?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, tGcqj6wny, COj8n3hCj, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Pv2Vu2yj4", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear10gkkme = activeVariantCallback(async (...args) => {
await delay(() => setVariant("K0ppaNhY1"), 1)
})

const onAppear9on1pp = activeVariantCallback(async (...args) => {
await delay(() => setVariant("adCH5Tcs0"), 1000)
})

const onAppearaedvuy = activeVariantCallback(async (...args) => {
await delay(() => setVariant("WNGq34VDP"), 350)
})

const onAppearll5sjm = activeVariantCallback(async (...args) => {
await delay(() => setVariant("h_YgL_jWN"), 1000)
})

const onAppear10if2kp = activeVariantCallback(async (...args) => {
await delay(() => setVariant("K0ppaNhY1"), 350)
})

useOnVariantChange(baseVariant, {adCH5Tcs0: onAppearaedvuy, default: onAppear10gkkme, h_YgL_jWN: onAppear10if2kp, K0ppaNhY1: onAppear9on1pp, WNGq34VDP: onAppearll5sjm})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({K0ppaNhY1: {value: transition2}, WNGq34VDP: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-xg9s22", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"Pv2Vu2yj4"} ref={ref ?? ref1} style={{backgroundColor: "rgba(255, 255, 255, 0.18)", borderBottomLeftRadius: radiusForCorner(tGcqj6wny, 3), borderBottomRightRadius: radiusForCorner(tGcqj6wny, 2), borderTopLeftRadius: radiusForCorner(tGcqj6wny, 0), borderTopRightRadius: radiusForCorner(tGcqj6wny, 1), ...style}} {...addPropertyOverrides({adCH5Tcs0: {"data-framer-name": "Variant 3"}, h_YgL_jWN: {"data-framer-name": "Variant 5"}, K0ppaNhY1: {"data-framer-name": "Variant 2"}, WNGq34VDP: {"data-framer-name": "Variant 4"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1xld128"} data-framer-name={"Moving Light"} layoutDependency={layoutDependency} layoutId={"XzIBFYQh6"} style={{background: "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.7) 0%, rgba(0, 0, 0, 0) 100%)"}}/><motion.div className={"framer-prj2p"} data-framer-name={"Container"} layoutDependency={layoutDependency} layoutId={"EH6WukONL"} style={{backgroundColor: COj8n3hCj, borderBottomLeftRadius: radiusForCorner(tGcqj6wny, 3), borderBottomRightRadius: radiusForCorner(tGcqj6wny, 2), borderTopLeftRadius: radiusForCorner(tGcqj6wny, 0), borderTopRightRadius: radiusForCorner(tGcqj6wny, 1)}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hbW4e.framer-wujv3i, .framer-hbW4e .framer-wujv3i { display: block; }", ".framer-hbW4e.framer-xg9s22 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 45px; justify-content: center; overflow: hidden; padding: 1px; position: relative; width: 182px; will-change: var(--framer-will-change-override, transform); }", ".framer-hbW4e .framer-1xld128 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 40px); left: -20px; overflow: visible; position: absolute; top: -20px; width: 40px; z-index: 1; }", ".framer-hbW4e .framer-prj2p { flex: 1 0 0px; height: 1px; overflow: visible; position: relative; width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-hbW4e.framer-xg9s22 { gap: 0px; } .framer-hbW4e.framer-xg9s22 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-hbW4e.framer-xg9s22 > :first-child { margin-top: 0px; } .framer-hbW4e.framer-xg9s22 > :last-child { margin-bottom: 0px; } }", ".framer-hbW4e.framer-v-1ls7464 .framer-1xld128 { left: unset; right: -20px; }", ".framer-hbW4e.framer-v-1n84n7b .framer-1xld128 { bottom: -20px; left: unset; right: -20px; top: unset; }", ".framer-hbW4e.framer-v-5iygy8 .framer-1xld128 { bottom: -20px; height: var(--framer-aspect-ratio-supported, 50px); top: unset; width: 50px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 45
 * @framerIntrinsicWidth 182
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"K0ppaNhY1":{"layout":["fixed","fixed"]},"adCH5Tcs0":{"layout":["fixed","fixed"]},"WNGq34VDP":{"layout":["fixed","fixed"]},"h_YgL_jWN":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"tGcqj6wny":"radius","COj8n3hCj":"background"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerW8KcEyV99: React.ComponentType<Props> = withCSS(Component, css, "framer-hbW4e") as typeof Component;
export default FramerW8KcEyV99;

FramerW8KcEyV99.displayName = "Helper/Moving BG 1";

FramerW8KcEyV99.defaultProps = {height: 45, width: 182};

addPropertyControls(FramerW8KcEyV99, {variant: {options: ["Pv2Vu2yj4", "K0ppaNhY1", "adCH5Tcs0", "WNGq34VDP", "h_YgL_jWN"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4", "Variant 5"], title: "Variant", type: ControlType.Enum}, tGcqj6wny: {defaultValue: 16, title: "Radius", type: ControlType.Number}, COj8n3hCj: {defaultValue: "rgb(25, 27, 39)", title: "Background", type: ControlType.Color}} as any)

addFonts(FramerW8KcEyV99, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})